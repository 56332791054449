import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'temperature-conversion'

export default {
  label: LABEL,
  name: 'Fahrenheit to Celsius',
  action: `
  Let's say your device is reporting temperature in Fahrenheit, but you would like to save the data in Celsius.
  Since the Math Node evaluates expressions, it's great for processing any formulas for data conversion.

  In this test, a JSON object containing a random Fahrenheit temperature, "fahrenheit", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is the value, converted to Celsius, rounded to the nearest integer.`,
  examples: [
    {
      input: { fahrenheit: 50 },
      output: {
        result: 10,
      },
    },
    {
      input: { fahrenheit: 100 },
      output: {
        result: 38,
      },
    },
  ],
  recommended: `Resources: [Math Node](/workflows/logic/math/)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function tempConvert(webhook, test) {
      const num = Chance.floating({ min: 50, max: 100 }).toFixed(2)

      const expectedValue = (((num - 32) * 5) / 9).toFixed(0)

      const input = { fahrenheit: num }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }

      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], tempConvert)

    return this
  },
}
