import React from 'react'
import PropTypes from 'prop-types'
import Button from './template-tester/button'

const DownloadBtn = ({ btnLabel, encoding, content, fileName }) => {
  const onClick = (e) => {
    e.preventDefault()
    const element = document.createElement('a')
    const file = new Blob([content], { type: encoding })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }
  return <Button onClick={onClick}>{btnLabel}</Button>
}

DownloadBtn.propTypes = {
  encoding: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  btnLabel: PropTypes.string.isRequired,
}

export default DownloadBtn
