const isGoogleLoaded = () => {
  return typeof window !== `undefined` && window.ga
}

export const trackEvent = (args) => {
  if (!isGoogleLoaded()) {
    return
  }
  window.ga('send', {
    hitType: 'event',
    ...args,
  })
}
