import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'string-split'

export default {
  label: LABEL,
  name: 'String Split',
  action: `
  Data can sometimes come to a workflow in one large string that needs to be divided. The goal of this test is for you to parse the array at "string" into an array of number values.

  Your webhook will receive a JSON object containing comma-separated numbers at "string". Return a JSON object containing
  a single field, "result", that is the parsed array.`,
  recommended: `Resources: [String Node](/workflows/logic/math/), [Loop Node](/workflows/logic/loop/)`,
  examples: [
    {
      input: { string: '1,1,0,0,1,1,1,0' },
      output: {
        result: [1, 1, 0, 0, 1, 1, 1, 0],
      },
    },
    {
      input: { string: '0,0,0' },
      output: { result: [0, 0, 0] },
    },
  ],
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function splitString(webhook, test) {
      const numbers = []
      const arraySize = Chance.integer({ min: 1, max: 20 })

      for (let i = 0; i < arraySize; i += 1) {
        numbers.push(Chance.integer({ min: 0, max: 1 }))
      }

      const expectedValue = numbers

      const input = { string: numbers.toString() }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], splitString)

    return this
  },
}
