import { assert } from 'chai'
import * as C from 'chance'
import * as R from 'ramda'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'array-groupBy'

export default {
  label: LABEL,
  name: 'Array Group By',
  action: `In some cases, data coming into WEGnology will not be organized in a way that makes it easy to act upon within the Visual Workflow Engine. The Group By method in the Array Node allows data in an array of objects to be grouped by a given payload path. For example, you can use it to sort a list of devices by class. 
  
  In this test, a random array of objects called "devices" will be provided to your Webhook. Your workflow should return a JSON object containing a single field, "result", that is an object of devices sorted by their "class."`,
  examples: [
    {
      input: {
        devices: [
          { id: 'rqw3r3', name: 'Device 1', class: 'gateway' },
          { id: '12398f', name: 'Device 1', class: 'standalone' },
        ],
      },
      output: {
        result: {
          gateway: [{ id: 'rqw3r3', name: 'Device 1', class: 'gateway' }],
          standalone: [{ id: '12398f', name: 'Device 2', class: 'standalone' }],
        },
      },
    },
  ],
  recommended: `Resources: [Array Node](/workflows/logic/array/)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function groupBy(webhook, test) {
      const devices = []
      const arraySize = Chance.integer({ min: 1, max: 10 })

      const deviceClasses = [
        'gateway',
        'standalone',
        'edge-compute',
        'system',
        'peripheral',
      ]

      // build random array of devices
      for (let i = 0; i < arraySize; i += 1) {
        const deviceObject = {
          id: Chance.guid(),
          name: Chance.animal(),
          class:
            deviceClasses[
              Chance.integer({ min: 0, max: deviceClasses.length - 1 })
            ],
        }

        devices.push(deviceObject)
      }

      const byClass = R.groupBy(function (device) {
        return device.class
      })

      const expectedValue = byClass(devices)

      const input = { devices }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], groupBy)

    return this
  },
}
