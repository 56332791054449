import { assert } from 'chai'
import * as C from 'chance'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'array-index'

export default {
  label: LABEL,
  name: 'Find Index in Array',
  action: `
  Finding the index of an element in an array is a useful practice, and this test allows you to do exactly that. 

  A JSON object containing a random number, "random", and an array of random numbers, "numbers", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", with the first index at which "random" exists in the "numbers" array.`,
  examples: [
    {
      input: {
        random: 77,
        numbers: [44, 37, 52, 18, 60, 86, 77, 41, 12],
      },
      output: {
        result: 6,
      },
    },
    {
      input: {
        random: 10,
        numbers: [19, 21, 10, 16],
      },
      output: { result: 2 },
    },
  ],
  recommended: `Resources: [Array Node](/workflows/logic/array/)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function arrayIndex(webhook, test) {
      const numbers = Chance.n(
        Chance.integer,
        Chance.integer({ min: 2, max: 25 }),
        { min: 0, max: 100 }
      )

      const random = Chance.pickone(numbers)
      const expectedValue = numbers.indexOf(random)

      const input = { random, numbers }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], arrayIndex)

    return this
  },
}
