import * as C from 'chance'
import { assert } from 'chai'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'count-two-workflow-runs'

export default {
  label: LABEL,
  name: 'Counting Two Workflow Runs',
  action: `
  This test is the same as Counting Workflow Runs, but this time, you'll need to keep track of two counters.
  
  Your workflow will be executed multiple times with an payload containing an "id". 
  When you receive a payload that contains a “count” and "id" field, you must return the number of times that "id" has been previously executed and reset your counter. 
  This process will be repeated several times. Each time you receive a payload with a “count” field, you must return the number of workflows executed since the previous “count” payload received.`,
  multi: true,
  recommended: `Resources: [Storage: Set Value Node](/workflows/data/store-value/), [Storage: Get Value Node](/workflows/data/get-value/)`,
  examples: [
    {
      input: {
        count: true,
        id: 'a',
      },
      output: 'First execution, result ignored',
    },
    {
      input: {
        count: true,
        id: 'b',
      },
      output: 'First execution, result ignored',
    },
    {
      input: { id: 'b' },
      output: { result: {} },
    },
    {
      input: { id: 'a' },
      output: { result: {} },
    },
    {
      input: {
        count: true,
        id: 'b',
      },
      output: { result: 1 },
    },
  ],
  async exercise(endpoint) {
    async function countWorkflowRuns(webhook, test) {
      const numOfTotalExecutions = Chance.integer({ min: 5, max: 12 }) // generate a random number for the count
      const expectedResult = {
        a: 0,
        b: 0,
      }
      const context = []

      let passed = false
      let message = null

      for (let i = 0; i <= numOfTotalExecutions; i += 1) {
        const id = Chance.integer({ min: 0, max: 1 }) ? 'a' : 'b'

        let input = { id }
        let expected = { result: {} }
        let ignore = false // ignore result
        let last = false // we need to know if this is the last one

        if (i === 0 || i === 1) {
          // reset on the first two
          const resetId = i ? 'a' : 'b'
          input = { count: true, id: resetId }
          expected = `First execution, result ignored`
          ignore = true
        } else if (i === numOfTotalExecutions) {
          // on the last, check for results
          input = { count: true, id }
          last = true
          expected = expectedResult[id]
        } else {
          expectedResult[id] += 1
        }

        let body = null
        let actual = null
        try {
          body = await Test.call(webhook, test.label, input)
          actual = body.data
          if (ignore) {
            actual = `Result ignored.`
          }
          if (!ignore && !last) {
            // if it's not the first or last, we neet to compare {} and {} so the notstrict is needed
            assert.notStrictEqual(
              body.data.result,
              expected,
              `Expected result to equal ${expected}`
            )
          } else if (last) {
            assert.equal(
              body.data.result,
              expectedResult[id],
              `Expected result to equal ${expected}`
            )
          }
          passed = true
        } catch (error) {
          passed = false
          message = error
        }

        context.push({
          input,
          expected,
          actual,
          body,
          passed,
          message,
        })
      }

      test.addContext(context, passed, message)

      return passed
    }

    await Test.performMultipleTimes(5, [endpoint, this], countWorkflowRuns)

    return this
  },
}
