import Test from '../../Test'

import addRandomArray from './addRandomArray'
import arrayIndex from './arrayIndex'
import addToEachElement from './addToEachElement'
import arrayFlatten from './arrayFlatten'
import groupBy from './groupBy'

const exercises = {}

exercises[addRandomArray.label] = new Test(addRandomArray)
exercises[arrayIndex.label] = new Test(arrayIndex)
exercises[addToEachElement.label] = new Test(addToEachElement)
exercises[arrayFlatten.label] = new Test(arrayFlatten)
exercises[groupBy.label] = new Test(groupBy)

export default {
  name: 'Array',
  isRunning: false,
  webhook: null,
  description: `
An [array](https://www.w3schools.com/js/js_arrays.asp) is a list of things. In most IoT applications, you will have to deal with arrays.

Each question in this test suite involves solving [Array Node](/workflows/logic/array/) questions using a 
variety of functionality within [WEGnology's Workflow Engine](/workflows/overview/). 

The Starter Workflow for this test suite is available [here](https://github.com/WEGnology/wegnology-workflow-lab/blob/e1c52d37c866a87fd3ec06e25f294603af7f8c58/starter-workflows/starter-workflow-array.flow).`,
  exercises,
}
