import React, { useContext, useEffect } from 'react'
import { Navbar } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import Styles from '../styles/topbar.module.scss'
import LosantAvatar from './LosantAvatar'
import LayoutContext from './LayoutContext'

const TopBar = ({ location }) => {
  const { layoutState, layoutDispatch } = useContext(LayoutContext)
  const { isMobileNavOpen } = layoutState

  const mobileNavHandler = () => {
    layoutDispatch({ type: 'toggleMobileNav', state: !isMobileNavOpen })
  }

  useEffect(() => {
    layoutDispatch({ type: 'toggleMobileNav', state: false })
    // eslint-disable-next-line
  }, [location])

  return (
    <Navbar fixed="top" className={Styles.TopBar}>
      <div className={Styles.TopBarWrapper}>
        <div className={Styles.MobileMenu}>
          <FontAwesomeIcon onClick={mobileNavHandler} icon={faBars} />
        </div>
        <div className={Styles.Image}>
          <a href="/">
            <img
              src="/images/weg_logo.svg"
              alt="WEGnology | IoT Application Platform"
              title="WEGnology | IoT Application Platform"
              style={{ width: '60px' }}
            />
          </a>
        </div>
        <div className={Styles.LinkGroup}>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.weg.net/digital/blog/"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://forums.app.wnology.io/"
              >
                Forums
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:support@wnology.io?subject=Support%20WEGnology"
              >
                Support
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://materiais.wegdigital.weg.net/lp-weg-digital-piw"
              >
                Contact
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://status.wnology.io/"
              >
                Status Page
              </a>
            </li>
          </ul>
        </div>

        <div className={Styles.Button}>
          <LosantAvatar />
        </div>
      </div>
    </Navbar>
  )
}

export default TopBar
