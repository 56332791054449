import * as C from 'chance'
import { assert } from 'chai'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'decode-hex'

export default {
  label: LABEL,
  name: 'Decoding Hex',
  action: `
  Binary data can be sent to the cloud in many different formats. For example, Sigfox messages are small and optimized for sensors and are hexadecimal values containing bytes of data. In this test, you will parse a hexadecimal value.

  A JSON object with a "raw" field will be provided to your Webhook. The "raw" field will
  contain two integers (4 bytes each) encoded as little-endian inside a single hex string.
  Return a JSON object containing two fields, "temperature" and "voltage". The "temperature"
  value is encoded in the first four bytes of the hex string.
  The "voltage" value is encoded in the last four bytes of the hex string.
  
  `,
  recommended: `Resources: [Function Node](/workflows/logic/function/)`,
  examples: [
    {
      input: { raw: '480000000c000000' },
      output: { result: { temperature: 72, voltage: 12 } },
    },
  ],
  async exercise(userWebhook) {
    async function testIteration(webhook, test) {
      const temperature = Chance.integer({ min: 60, max: 100 })
      const voltage = Chance.integer({ min: 0, max: 150 })

      const buffer = Buffer.alloc(8)
      buffer.writeInt32LE(temperature)
      buffer.writeInt32LE(voltage, 4)
      buffer.toString('hex')

      const expectedValue = { temperature, voltage }
      const input = { raw: buffer.toString('hex') }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      let message = null

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
        message = error
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          body,
          message,
        },
        passed,
        message
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], testIteration)

    return this
  },
}
