import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'array-length'

export default {
  label: LABEL,
  name: 'Finding Array Length',
  action: `
  You may want to know how many data points were returned from your device, and you'll have to count them to find out. This test is about finding the length of an array, let's get started.

  A JSON object containing two random arrays, "array1" and "array2", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is the summed length of both arrays.
  `,
  examples: [
    {
      input: { array1: [46, 27, 62, 17], array2: [27, 1, 10] },
      output: {
        result: 7,
      },
    },
    {
      input: {
        array1: [19],
        array2: [14, 88, 27, 7, 11],
      },
      output: { result: 6 },
    },
  ],
  recommended: `Resources: [Math Node](/workflows/logic/math/), [Format Helpers](/workflows/accessing-payload-data/#format-helpers)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function compareEmail(webhook, test) {
      const array1 = []
      const array2 = []

      for (let i = 0; i < Math.floor(Math.random() * 20) + 5; i += 1) {
        array1.push(Chance.integer({ min: 0, max: 100 }))
      }
      for (let j = 0; j < Math.floor(Math.random() * 40) + 5; j += 1) {
        array2.push(Chance.integer({ min: 0, max: 100 }))
      }
      const expectedValue = array2.length + array1.length
      const input = { array1, array2 }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], compareEmail)

    return this
  },
}
