import * as C from 'chance'
import { assert } from 'chai'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'decode-json'

export default {
  label: LABEL,
  name: 'Decoding JSON',
  action: `
  It is important for our Workflows to parse incoming JSON, the extremely popular data-interchange format. In this test, you will practice exactly that. 
  
  For this JSON decoding exercise, a JSON object with a "raw" field will be provided to your Webhook. The "raw" field contains
  an unencoded JSON string with two numbers, "num1" and "num2". Return a JSON object containing
  a single field, "result", that is the sum of these two numbers.
  `,
  examples: [
    {
      input: { raw: JSON.stringify({ num1: 5, num2: 8 }) },
      output: { result: 13 },
    },
  ],
  recommended: `Resources: [JSON: Decode Node](/workflows/logic/json-decode/), [Math Node](/workflows/logic/math/)`,
  async exercise(userWebhook) {
    async function testIteration(webhook, test) {
      const num1 = Chance.integer({ min: -20, max: 100 })
      const num2 = Chance.integer({ min: 0, max: 150 })

      const expectedValue = num1 + num2
      const input = { raw: JSON.stringify({ num1, num2 }) }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      let message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
        message = error
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          body,
          message,
        },
        passed,
        message
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], testIteration)

    return this
  },
}
