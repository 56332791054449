import * as C from 'chance'
import moment from 'moment'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'get-date-element'

export default {
  label: LABEL,
  name: 'Get Date Element',
  action: `
  When parsing dates, there will be times where you will need to parse one element (e.g. day, month, year) from 
  the date and do something with the result. For example, if given the date "12/25/2019" and parsing out only the day, you would receive "25". 
  
  A JSON object containing one date, "date", and the element
  to be parsed "element", will be provided to your Webhook. Return a JSON object containing a single field, 
  "result", that is the "element" parsed from the "date".
  `,
  examples: [
    {
      input: {
        date: '2099-05-26T11:36:47.206Z',
        element: 'day',
      },
      output: {
        result: '5',
      },
    },
    {
      input: {
        date: '2019-09-06T13:30:52.557Z',
        element: 'year',
      },
      output: {
        result: '2019',
      },
    },
    {
      input: {
        date: '2019-09-06T13:30:52.557Z',
        element: 'month',
      },
      output: {
        result: '9',
      },
    },
  ],
  recommended: `Resources: [Format Helpers](/workflows/accessing-payload-data/#format-helpers), [Switch Node](/workflows/logic/switch/), [Format Strings](https://momentjs.com/docs/#/displaying/format/)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function format(webhook, test) {
      const date = Chance.date()
      const occurrence = Chance.integer({ min: 1, max: 3 })

      let expectedValue
      let element
      switch (occurrence) {
        case 1:
          // month
          element = 'month'
          expectedValue = moment.utc(date).format('M')
          break
        case 2:
          // day
          element = 'day'
          expectedValue = moment.utc(date).format('D')
          break
        case 3:
          // year
          element = 'year'
          expectedValue = moment.utc(date).format('YYYY')
          break
        default:
          return false
      }

      const input = { date, element }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], format)

    return this
  },
}
