import { assert } from 'chai'
import Test from '../../Test'

export const LABEL = 'add-two-numbers'

export default {
  label: LABEL,
  name: 'Add Two Random Numbers',
  examples: [
    {
      input: { num1: 3, num2: 5 },
      output: {
        result: 8,
      },
    },
    {
      input: {
        num1: 7,
        num2: 21,
      },
      output: { result: 28 },
    },
  ],
  action: `
  Adding two numbers is a very simple task, but it allows us to get warmed up in the Workflow Engine.
  The Workflow Engine is all about manipulating the payload, which means you need to know how to access
  different parts of the payload. [Payload paths](/workflows/accessing-payload-data/#payload-paths) are dot-separated references to object properties, such as \`data.body.num1\`.
  
  In this test, a JSON object containing two random numbers, "num1" and "num2", will be provided to your Webhook.
  Your workflow should return a JSON object containing a single field, "result", that is the sum of "num1" and "num2".`,
  recommended: `Resources: [Math Node](/workflows/logic/math/)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function addTwoNumbers(webhook, test) {
      const num1 = Math.floor(Math.random() * 100)
      const num2 = Math.floor(Math.random() * 100)

      const expectedValue = num1 + num2
      const input = { num1, num2 }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], addTwoNumbers)

    return this
  },
}
