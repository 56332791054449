import { assert } from 'chai'
import Test from '../../Test'

export const LABEL = 'add-three-numbers'

export default {
  label: LABEL,
  name: 'Add Three Random Numbers',
  action: `
  The Math Node is meant to process [expressions](https://en.wikipedia.org/wiki/Expression_(mathematics)). An expression is a combination of 
  [string templates](/workflows/accessing-payload-data/#string-templates) and [operators](/workflows/accessing-payload-data/#supported-operators) that forms a [“JavaScript-like”](https://javascript.info/) expression.  

  In this test, a JSON object containing three random numbers, "num1", "num2", and "num3", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is the sum of these three numbers.
  `,
  recommended: `Resources: [Math Node](/workflows/logic/math/)`,
  examples: [
    {
      input: { num1: 3, num2: 5, num3: 40 },
      output: {
        result: 48,
      },
    },
    {
      input: {
        num1: 19,
        num2: 21,
        num3: 2,
      },
      output: { result: 42 },
    },
  ],
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function addThreeNumbers(webhook, test) {
      const num1 = Math.floor(Math.random() * 100)
      const num2 = Math.floor(Math.random() * 100)
      const num3 = Math.floor(Math.random() * 100)
      const expectedValue = num1 + num2 + num3

      const input = { num1, num2, num3 }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], addThreeNumbers)

    return this
  },
}
