import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'random-number-between-two'

export default {
  label: LABEL,
  name: 'Random Number Between Two',
  action: `
  WEGnology provides a [Device Simulator](/devices/simulator/), but it's really common for WEGnology Developers
  to use the Workflow Engine to generate random data and report it as [device state](/workflows/outputs/device-state/).  
  
  In this test, a JSON object containing two random numbers, "num1" and "num2", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is a random number between "num1" and "num2" (exclusive).`,
  examples: [
    {
      input: { num1: 3, num2: 50 },
      output: {
        result: 39,
      },
    },
    {
      input: { num1: 100, num2: 500 },
      output: {
        result: 101,
      },
    },
  ],
  recommended: `Resources: [Random Number Node](/workflows/logic/random-number/)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function randomNumberBetween(webhook, test) {
      const num1 = Chance.integer({ min: 0, max: 300 })
      const num2 = Chance.integer({ min: num1 + 1, max: 500 })

      const input = { num1, num2 }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = `A number between ${num1} and ${num2}`
      const actual = body.data

      let passed = false
      const message = null

      try {
        const { result } = body.data
        const isInBetween = result > num1 && result < num2

        assert.equal(isInBetween, true)
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], randomNumberBetween)

    return this
  },
}
