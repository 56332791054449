import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'round-one-number'

export default {
  label: LABEL,
  name: 'Round One Number',
  action: `
  There will be cases where you'll receive a number from a [Device](/devices/overview/)
  and you'll want it to be rounded for display purposes. The Math Node supports a number
  of [functions](/workflows/accessing-payload-data/#supported-functions). One, in particular, will help you solve this test.

  In this test, a JSON object containing one random number, "num", will be provided to your Webhook.
  Round the number to the closest integer and return a JSON object containing a single field, "result."`,
  examples: [
    {
      input: { num: 4.4 },
      output: {
        result: 4,
      },
    },
    {
      input: { num: 90.1 },
      output: {
        result: 90,
      },
    },
  ],
  recommended: `Resources: [Math Node](/workflows/logic/math/), [Supported Functions](/workflows/accessing-payload-data/#supported-functions)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function roundOneNumber(webhook, test) {
      const num = Chance.floating({ min: 0, max: 100 })
      const expectedValue = Math.round(num)

      const input = { num }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }

      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], roundOneNumber)

    return this
  },
}
