import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'required-fields'

export default {
  label: LABEL,
  name: 'Required Fields',
  examples: [
    {
      input: {
        email: 'jane@example.com',
        password: 'my-password',
        firstName: 'Jane',
      },
      output: {
        error: 'One or more user fields are missing.',
      },
      body: {
        status: 400,
      },
    },
    {
      input: {
        email: 'jane@example.com',
        password: 'my-password',
        firstName: 'Jane',
        lastName: 'Smith',
      },
      output: { result: 'success' },
      body: {
        status: 200,
      },
    },
  ],
  recommended: `Resources: [Validate Payload Node](/workflows/logic/validate-payload/)`,
  action: `
  This exercise mimics a user registration request. 
  
  A valid registration request will be a JSON object containing "email", "password", "firstName", and "lastName" fields. If any field is missing
  your webhook must respond with an HTTP Status Code of 400 and a JSON object containing a single field, "error",
  that contains the message "One or more user fields are missing.". If all fields are supplied, your
  webhook must respond with an HTTP Status Code of 200 and a JSON object containing a single field, "result",
  that contains the message "success".
  `,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function requiredFields(webhook, test) {
      const occurrence = Chance.integer({ min: 1, max: 2 })

      const input = {
        email: Chance.email(),
        password: Chance.string(),
        firstName: Chance.name(),
        lastName: Chance.last(),
      }

      const randomKey = Chance.pickone(Object.keys(input))

      let expectedValue
      let expectedStatus

      switch (occurrence) {
        case 1:
          // all good
          expectedStatus = 200
          expectedValue = { result: 'success' }
          break
        case 2:
          // delete one key
          delete input[randomKey]
          expectedStatus = 400
          expectedValue = { error: 'One or more user fields are missing.' }
          break
        default:
          break
      }

      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = expectedValue
      const actual = body.data

      let passed = false
      let message = null

      try {
        assert.deepEqual(
          body.data,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        assert.equal(
          body.status,
          expectedStatus,
          `Expected status code to equal ${expectedStatus}`
        )
        passed = true
      } catch (error) {
        message = error
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], requiredFields)

    return this
  },
}
