import * as C from 'chance'
import { assert } from 'chai'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'decode-base64'

export default {
  label: LABEL,
  name: 'Decoding Base64',
  action: `
  A [Base64](https://en.wikipedia.org/wiki/Base64)-encoded string representing binary data is often provided by IoT devices. 
  
  For this exercise, a JSON object containing a Base64 encoded string, "value", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is the decoded value of that string.
  
  `,
  examples: [
    {
      input: {
        value: 'aGVsbG8&#x3D;',
      },
      output: {
        result: 'hello',
      },
    },
    {
      input: {
        value: 'd29ybGQ&#x3D;',
      },
      output: { result: 'world' },
    },
  ],
  recommended: `Resources: [Format Helpers](/workflows/accessing-payload-data/#format-helpers)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function testIteration(webhook, test) {
      const randomString = btoa(Chance.word())
      const expectedValue = atob(randomString)
      const input = { value: randomString }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      let message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
        message = error
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          body,
          message,
        },
        passed,
        message
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], testIteration)

    return this
  },
}
