import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'check-for-even-numbers'

export default {
  label: LABEL,
  name: 'Checking for Even Numbers',
  action: `
  Alerting based off a value received from a device is one of the top use cases of the Workflow Engine, but 
  this requires some [conditional](/workflows/logic/conditional/) logic. 

  In this test, a JSON object containing a single random integer, "num", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is true if the number
  is even and false if the number is odd.
  `,
  recommended: `Resources: [Conditional Node](/workflows/logic/conditional/), [Math Node](/workflows/logic/math/)`,
  examples: [
    {
      input: { num: 16 },
      output: {
        result: true,
      },
    },
    {
      input: {
        num: 19,
      },
      output: { result: false },
    },
  ],
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function checkForEven(webhook, test) {
      const num = Chance.integer({ min: 0, max: 100 })

      const expectedValue = num % 2 === 0

      const input = { num }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], checkForEven)

    return this
  },
}
