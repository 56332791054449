import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'format-number'

export default {
  label: LABEL,
  name: 'Formatting Numbers',
  action: `
  Numbers can be difficult to work with if they are reported in the wrong format. Let's say all you needed was your decimal number displayed on a dashboard in dollar format, well then format helpers just came to your rescue. 
  
  In this test, a JSON object containing a random number, "number", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", which is the original number rounded to two decimal places and returned in U.S. English format, which is commas in the thousands position and periods for the decimal position (e.g. 32,564.23).
  `,
  examples: [
    {
      input: { number: 1689.3489 },
      output: {
        result: '1,689.35',
      },
    },
    {
      input: {
        number: 3.14159265,
      },
      output: { result: 3.14 },
    },
  ],
  recommended: `Resources: [Format Helpers](/workflows/accessing-payload-data/#format-helpers), [D3 Number Formatting](https://github.com/d3/d3-format#locale_format)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function format(webhook, test) {
      const number = Chance.floating({ min: 1000, max: 999999 })

      const expectedValue = number
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      const input = { number }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], format)

    return this
  },
}
