import { assert } from 'chai'

import Test from '../../Test'

export const LABEL = 'mutate-add'

export default {
  label: LABEL,
  name: 'Mutate Add',
  action: `
  If you were going to build a counter using the Workflow Engine (which happens in a future Workflow Lab test), you need 
  to be able to initialize the value of "0" to start counting. This technique is also helpful if you ever
  need to create values on the payload. 
  
  In this test, an empty JSON object will be provided to your Webhook. Return a JSON object containing a 
  single field, "result", that includes an object with a single key of "counter" which has a value of the number 0.
  `,
  recommended: `Resources: [Mutate Node](/workflows/logic/mutate/)`,
  examples: [
    {
      input: {},
      output: {
        result: {
          counter: 0,
        },
      },
    },
  ],
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function mutateAdd(webhook, test) {
      const expectedValue = {
        counter: 0,
      }

      const input = {}
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message: null,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(3, [userWebhook, this], mutateAdd)

    return this
  },
}
