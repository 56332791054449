import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'string-separation'

export default {
  label: LABEL,
  name: 'String Separation',
  action: `
  Sometimes when data comes back from devices, you'll need to parse only some of the input. This test 
  is a simplified version of a parsing exercise. 
  
  A JSON object containing a random even-length string, 
  "string", will be provided to your Webhook. Return a JSON object containing a single field, "result", 
  that is the first half of the string.
  `,

  examples: [
    {
      input: { string: '2242' },
      output: { result: '22' },
    },
    {
      input: { string: 'rock' },
      output: { result: 'ro' },
    },
  ],
  recommended: `Resources: [Math Node](/workflows/logic/math/), [String Node](/workflows/logic/string/)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function stringConcat(webhook, test) {
      const pool = '0123456789'
      const stringLength = Math.round(Chance.integer({ min: 1, max: 10 }) * 2)
      const string = Chance.string({ length: stringLength, pool })

      const length = string.length / 2

      const expectedValue = string.toString().substring(0, length)

      const input = { string }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], stringConcat)

    return this
  },
}
