import * as C from 'chance'
import { assert } from 'chai'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'encode-base64'

export default {
  label: LABEL,
  name: 'Encoding Base64',
  action: `
  In the decoding test, you parsed a [Base64](https://en.wikipedia.org/wiki/Base64)-encoded string representing binary data. In this test, your workflow must create a [Base64](https://en.wikipedia.org/wiki/Base64)-encoded string.
  
  For this exercise, a JSON object containing a random string, "value", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is the Base64
  encoded value of that string.
  `,
  examples: [
    {
      input: { value: 'hello' },
      output: { result: 'aGVsbG8&#x3D;' },
    },
  ],
  recommended: `Resources: [Format Helpers](/workflows/accessing-payload-data/#format-helpers), [Escaping HTML](/workflows/accessing-payload-data/#escaping-html)`,

  async exercise(userWebhook) {
    async function testIteration(webhook, test) {
      const randomString = Chance.word()
      const expectedValue = btoa(randomString)
      const input = { value: randomString }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      let message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
        message = error
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          body,
          passed,
          message,
        },
        passed,
        message
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], testIteration)

    return this
  },
}
