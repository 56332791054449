import { assert } from 'chai'
import * as C from 'chance'
import * as R from 'ramda'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'array-flatten'

export default {
  label: LABEL,
  name: 'Flatten Array',
  action: `Third-party data comes back in all sorts of ways. Sometimes, you'll get back something 
  like \`[1, [2, [3], 4], [5]]\` and you'll just need a flat array: \`[1, 2, 3, 4, 5]\`.  
  
  In this test, a JSON object containing a randomly nested array called "numbers"
  will be provided to your Webhook. Return a JSON object containing a single field, "result", with a flattened version of that array.`,
  examples: [
    {
      input: {
        numbers: [44, [37], [[52, 18], 60, 86], 77, 41, [12]],
      },
      output: {
        result: [44, 37, 52, 18, 60, 86, 77, 41, 12],
      },
    },
    {
      input: {
        numbers: [1, [2, [3], 4], [5]],
      },
      output: { result: [1, 2, 3, 4, 5] },
    },
  ],
  recommended: `Resources: [Array Node](/workflows/logic/array/)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function flattenArray(webhook, test) {
      const numbers = []
      const arraySize = Chance.integer({ min: 1, max: 10 })

      for (let i = 0; i < arraySize; i += 1) {
        const nestedArray = []
        const nestedArraySize = Chance.integer({ min: 1, max: 5 })
        for (let j = 0; j < nestedArraySize; j += 1) {
          nestedArray.push(Chance.integer({ min: 1, max: 100 }))
        }

        numbers.push(nestedArray)
      }
      const expectedValue = R.flatten(numbers)

      const input = { numbers }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], flattenArray)

    return this
  },
}
