import * as C from 'chance'
import { assert } from 'chai'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'count-workflow-runs'

export default {
  label: LABEL,
  name: 'Counting Workflow Runs',
  action: `This exercise will help familiarize you with Workflow Storage, which is incredibly useful when using the same value in different workflow runs. 
  
  Your workflow will be executed multiple times with an empty payload. When you receive a payload that contains a “count” field, you must return the number of times it has been previously executed and reset your counter. 
  This process will be repeated several times. Each time you receive a payload with a “count” field, you must return the number of workflows executed since the previous “count” payload received.`,
  multi: true,
  recommended: `Resources: [Storage: Set Value Node](/workflows/data/store-value/), [Storage: Get Value Node](/workflows/data/get-value/)`,
  examples: [
    {
      input: {
        count: true,
      },
      output: 'First execution, result ignored',
    },
    {
      input: {},
      output: { result: {} },
    },
    {
      input: {},
      output: { result: {} },
    },
    {
      input: {
        count: true,
      },
      output: { result: 2 },
    },
  ],
  async exercise(endpoint) {
    async function countWorkflowRuns(webhook, test) {
      const numOfTotalExecutions = Chance.integer({ min: 3, max: 10 }) // generate a random number for the count
      const resetInput = { count: true } // this is the input to reset the workflow
      const expectedResult = numOfTotalExecutions - 1
      const context = []

      let passed = false
      let message = null

      for (let i = 0; i <= numOfTotalExecutions; i += 1) {
        let input = {}
        let expected = { result: {} }
        let ignore = false // ignore result
        let last = false // we need to know if this is the last one

        if (i === 0) {
          // reset on the first one
          input = resetInput
          expected = `First execution, result ignored`
          ignore = true
        } else if (i === numOfTotalExecutions) {
          // on the last, check for results
          input = resetInput
          last = true
          expected = expectedResult
        }

        let body = null
        let actual = null
        try {
          body = await Test.call(webhook, test.label, input)
          actual = body.data
          if (ignore) {
            actual = `Result ignored.`
          }
          if (!ignore && !last) {
            // if it's not the first or last, we neet to compare {} and {} so the notstrict is needed
            assert.notStrictEqual(
              body.data.result,
              expected,
              `Expected result to equal ${expected}`
            )
          } else if (last) {
            assert.equal(
              body.data.result,
              expected,
              `Expected result to equal ${expected}`
            )
          }
          passed = true
        } catch (error) {
          passed = false
          message = error
        }

        context.push({
          input,
          expected,
          actual,
          body,
          passed,
          message,
        })
      }

      test.addContext(context, passed, message)

      return passed
    }

    await Test.performMultipleTimes(5, [endpoint, this], countWorkflowRuns)

    return this
  },
}
