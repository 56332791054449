import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css as style } from '@emotion/core'

const SuiteResults = ({ suite, counter }) => {
  const SuiteResultsWrapper = styled.div`
    display: flex;

    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;
  `

  const suiteCounter = counter[suite]
    ? counter[suite]
    : {
        pass: 1,
        fail: 1,
        notRun: 1,
        count: 3,
      }

  const green = parseInt(100 * (suiteCounter.pass / suiteCounter.count))
  const red = parseInt(100 * (suiteCounter.fail / suiteCounter.count))
  const notRun = parseInt(100 * (suiteCounter.notRun / suiteCounter.count))

  const Box = style`
    height: 5px;
    transition: flex-grow 500ms ease-in-out;
  `

  const Passed = styled.div`
    flex-grow: ${green};
    display: ${green === 0 ? 'none' : 'block'};
    background-color: #7cac19;
    border-radius: 4px 0px 0px 0px;
    ${Box}
  `

  const Failed = styled.div`
    flex-grow: ${red};
    display: ${red === 0 ? 'none' : 'block'};
    background-color: #fe1209;
    border-radius: ${green === 0 ? '4px' : '0px'}
      ${notRun === 0 ? '4px' : '0px'} 0px 0px;
    ${Box}
  `

  const somethingToTheLeft = green === 0 && red === 0
  const NotRun = styled.div`
    flex-grow: ${notRun};
    display: ${notRun === 0 ? 'none' : 'block'};
    background-color: #58bfc6;
    border-radius: ${somethingToTheLeft ? '4px' : '0px'} 4px 0px 0px;
    ${Box}
  `

  return (
    <>
      <SuiteResultsWrapper>
        <Passed />
        <Failed />
        <NotRun />
      </SuiteResultsWrapper>
    </>
  )
}

export default SuiteResults
