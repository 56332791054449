import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'string-concat'

export default {
  label: LABEL,
  name: 'String Concatenation',
  action: `
  In some cases, when data is provided to a workflow from a device, it is sent in separate pieces that need to be assembled. This test is a simple way to combine two strings that the device reported separately.

  A JSON object containing two random strings, "string1" and "string2", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is a concatenated string.
  `,
  examples: [
    {
      input: { string1: 'race', string2: 'car' },
      output: { result: 'racecar' },
    },
    {
      input: { string1: 'zabijica', string2: 'ohoco' },
      output: { result: 'zabijicaohoco' },
    },
  ],
  recommended: `Resources: [String Node](/workflows/logic/string/)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function stringConcat(webhook, test) {
      const string1 = Chance.word()
      const string2 = Chance.word()

      const expectedValue = `${string1}${string2}`

      const input = { string1, string2 }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], stringConcat)

    return this
  },
}
