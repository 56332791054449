import Math from './Math'
import Array from './Array'
import String from './String'
import WorkflowStorage from './WorkflowStorage'
import EncodingDecoding from './EncodingDecoding'
import Validation from './Validation'
import TemplateHelpers from './TemplateHelpers'
import Mutate from './Mutate'

export default {
  Math,
  Mutate,
  Array,
  String,
  TemplateHelpers,
  WorkflowStorage,
  EncodingDecoding,
  Validation,
  meta: {
    isRunning: false, // this is used in the front-end to know if something is running
    exerciseCount:
      Object.keys(Mutate.exercises).length +
      Object.keys(Math.exercises).length +
      Object.keys(Array.exercises).length +
      Object.keys(String.exercises).length +
      Object.keys(TemplateHelpers.exercises).length +
      Object.keys(WorkflowStorage.exercises).length + // there is prolly a better way to do this
      Object.keys(EncodingDecoding.exercises).length +
      Object.keys(Validation.exercises).length,
  },
}
