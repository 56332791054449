import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css as style } from '@emotion/core'

const LabResults = ({ counter }) => {
  const SuiteResultsWrapper = styled.div`
    display: flex;

    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;
  `
  const sumAllCounters = (accumulator, suite) => {
    accumulator.pass += counter[suite].pass
    accumulator.fail += counter[suite].fail
    accumulator.notRun += counter[suite].notRun
    accumulator.totalCount =
      accumulator.pass + accumulator.fail + accumulator.notRun
    return accumulator
  }

  const totals = Object.keys(counter).reduce(sumAllCounters, {
    pass: 0,
    fail: 0,
    notRun: 0,
    totalCount: 1,
  })

  const green = parseInt(100 * (totals.pass / totals.totalCount))
  const red = parseInt(100 * (totals.fail / totals.totalCount))
  const notRun = parseInt(100 * (totals.notRun / totals.totalCount))

  const Box = style`
  height: 30px;
  transition: flex-grow 500ms ease-in-out;
`

  const Passed = styled.div`
    flex-grow: ${green};
    display: ${green === 0 ? 'none' : 'block'};
    background-color: #7cac19;
    ${Box}
  `

  const Failed = styled.div`
    flex-grow: ${red};
    display: ${red === 0 ? 'none' : 'block'};
    background-color: #fe1209;
    ${Box}
  `

  const NotRun = styled.div`
    flex-grow: ${notRun};
    display: ${notRun === 0 ? 'none' : 'block'};
    background-color: #58bfc6;
    ${Box}
  `

  const ResultTests = styled.p`
    color: #8a93a8;
    margin-top: 10px;
    text-align: center;
  `

  return (
    <>
      <h2
        style={{
          marginTop: '0',
          color: 'black',
        }}
      >
        Lab Results
      </h2>
      <SuiteResultsWrapper>
        <Passed />
        <Failed />
        <NotRun />
      </SuiteResultsWrapper>
      <ResultTests>
        <span
          css={{
            color: '#7CAC19',
            textTransform: 'uppercase',
            fontFamily:
              "'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow', sans-serif",
          }}
        >
          {totals.pass} passed
        </span>{' '}
        |{' '}
        <span
          css={{
            color: '#FE1209',
            textTransform: 'uppercase',
            fontFamily:
              "'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow', sans-serif",
          }}
        >
          {totals.fail} fail
        </span>{' '}
        |{' '}
        <span
          css={{
            color: '#58BFC6',
            textTransform: 'uppercase',
            fontFamily:
              "'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow', sans-serif",
          }}
        >
          {totals.notRun} not run
        </span>
      </ResultTests>
    </>
  )
}

export default LabResults
