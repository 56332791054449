import React from 'react'
import PropTypes from 'prop-types'
import DownloadBtn from './DownloadButton'

const DisplayWithDownloadLink = ({
  btnLabel,
  encoding,
  content,
  fileName,
  format = 'json',
}) => {
  return (
    <div>
      <DownloadBtn
        content={content}
        btnLabel={btnLabel}
        encoding={encoding}
        fileName={fileName}
      />
      <pre className={`language-${format}`}>
        <code className={`language-${format}`}>{content}</code>
      </pre>
    </div>
  )
}

DisplayWithDownloadLink.propTypes = {
  ...DownloadBtn.propTypes,
  format: PropTypes.string,
}

export default DisplayWithDownloadLink
