import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'compare-email'

export default {
  label: LABEL,
  name: 'Compare Email',
  action: `
  In many places, you will need to compare values to see if they are equal. In a 
  [WEGnology Experience](/experiences/overview/) this could be comparing emails. 
  
  In this test, a JSON object containing two random emails, "email1" and "email2", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is \`true\` if the emails are equal and
   \`false\` if not. **Since they are emails, case should not be enforced.**`,
  recommended: `Resources: [String Node](/workflows/logic/string/), [Conditional Node](/workflows/logic/conditional/)`,
  examples: [
    {
      input: { email1: 'modbus@example.com', email2: 'Modbus@example.com' },
      output: {
        result: true,
      },
    },
    {
      input: { email1: 'iot@example.com', email2: 'wegnology@example.com' },
      output: {
        result: false,
      },
    },
  ],
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function compareEmail(webhook, test) {
      const pool = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const occurrence = Chance.integer({ min: 1, max: 3 })

      let expectedValue
      let email1
      let email2

      switch (occurrence) {
        case 1:
          // this case we are going to generate two random emails
          // we expect it to be false
          email1 = Chance.string({ pool })
          email2 = Chance.string({ pool })
          expectedValue = false
          break
        case 2:
          // this case we are going to generate the same email
          // we expect it to be true
          email1 = Chance.string({ pool })
          email2 = email1
          expectedValue = true
          break
        case 3:
          // this case we are going to generate the same email
          // we expect it to be true
          email1 = Chance.string({ pool })
          email2 = email1.toUpperCase()
          expectedValue = true
          break
        default:
          break
      }

      email1 = `${email1}@example.com`
      email2 = `${email2}@example.com`

      const input = { email1, email2 }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message: null,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], compareEmail)

    return this
  },
}
