import { assert } from 'chai'
import Axios from 'axios'

const timeout = (ms) => new Promise((res) => setTimeout(res, ms))

Axios.interceptors.request.use((request) => {
  request.ts = performance.now()
  return request
})

Axios.interceptors.response.use((response) => {
  const time = Number(performance.now() - response.config.ts).toFixed(2)
  response.timing = {
    time,
  }
  return response
})

class Test {
  constructor(object, label) {
    this.context = []
    this.passed = false
    this.run = false
    this.resultMessage = null
    this.didTimeout = false
    this.multi = object.multi || false
    this.isRunning = false
    this.examples = object.examples
    this.label = label || object.label
    this.action = object.action
    this.name = object.name
    this.exercise = object.exercise.bind(this)
    this.recommended = object.recommended
  }

  addContext(newContext, passed) {
    this.context.push(newContext)

    // update status
    this.passed = passed
  }

  clearContext() {
    this.context = []
  }

  assert(input, expected) {
    let didPass = true
    try {
      assert.equal(input, expected)
    } catch (error) {
      didPass = false
    }
    this.passed = didPass
  }

  static async call(endpoint, q, body, requestOptions) {
    const url = `${endpoint}?id=${q}`
    const options = {
      url,
      method: 'post',
      data: body,
      timeout: 2000,
      validateStatus: (status) => {
        // Reject only if the status code is greater than or equal to 500
        // This is needed for validation tests
        return status < 500
      },
      headers: { 'content-type': 'application/json' },
      ...requestOptions,
    }

    try {
      const response = await Axios.request(options)
      return response
    } catch (error) {
      return {
        data: error.toString(),
        status: 500,
        timing: {
          time: 0,
        },
      }
    }
  }

  static async performMultipleTimes(times, x, fn) {
    for (let i = 0; i < times; i += 1) {
      const result = await fn(...x)
      if (!result) break
      await timeout(100)
    }
  }
}

export default Test
