import Test from '../../Test'

// Tests :)
import requiredField from './requiredFields'

const exercises = {}

exercises[requiredField.label] = new Test(requiredField)

export default {
  name: 'Validation',
  isRunning: false,
  webhook: null,
  description: `
  Validating input is an important part of any IoT solution.
  If you're accepting user input from a WEGnology Experience, you must always ensure that you've received the details that
  you require and it's formatted correctly. Responding with appropriate codes and messages is also an important
  part of a well-designed system. This suite contains tests that will require your workflow to
  implement common validation techniques.

  The Starter Workflow for this test suite is available [here](https://github.com/WEGnology/wegnology-workflow-lab/blob/e1c52d37c866a87fd3ec06e25f294603af7f8c58/starter-workflows/starter-workflow-validation.flow).`,
  exercises,
}
