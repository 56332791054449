import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Prism from 'prismjs'
import 'prismjs/components/prism-json'
import { cloneDeep } from 'lodash'

export const EXAMPLE_FLOW_ID = '333333333333cccccccccccc'
export const EXAMPLE_APP_ID = '555555555555eeeeeeeeeeee'
export const EXAMPLE_DEVICE_ID = '222222222222bbbbbbbbbbbb'

// Taken from packages/losant-app-www/src/utils/example-payload-helpers.js

// sort the top-level keys alphabetically
// https://www.codegrepper.com/code-examples/javascript/alphabetize+the+properties+of+an+object+javascript
const sortObjectKeysAlphabetically = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce(
      (accumulator, current) => (
        // TODO: Dylan, we need some magic on this one
        (accumulator[current] = obj[current]), accumulator //eslint-disable-line
      ),
      {}
    )
}

// common to every flowClass
const BASE_PAYLOAD = {
  data: {}, // this will almost always be overwritten
  flowId: EXAMPLE_FLOW_ID,
  flowName: 'My Great Workflow',
  flowVersion: 'myFlowVersion',
  globals: {
    aJsonGlobal: {
      key: 'value',
    },
    aNumberGlobal: 42,
    aStringGlobal: 'My value',
  },
  time: 'DATE_OBJECT', // component overwrites this to match the display in the web app
  triggerId: '111111111111aaaaaaaaaaaa', // this will often get overwritten
  triggerType: 'REQUIRED. DO NOT LEAVE BLANK.',
}

// all but embedded
const APPLICATION_INFO = {
  applicationId: EXAMPLE_APP_ID,
  applicationName: 'My Great Application',
}

// this is always included for edge workflows,
// and may also be included via the 'isCloudDeviceTrigger' prop
const SIMPLE_DEVICE_INFO = {
  deviceName: 'My Great Device',
  deviceTags: {
    aTagKey: ['exampleTagValue'],
    tagWithMultipleValues: ['tagValue', 'anotherTagValue'],
  },
}

const SIMPLE_ATTRIBUTES = {
  // does not include attribute descriptions (edge)
  exampleNumber: {
    attributeTags: {
      attrTagKey: 'tagValue1',
    },
    dataType: 'number',
    name: 'exampleNumber',
  },
  exampleString: {
    attributeTags: {
      attrTagKey: 'tagValue2',
    },
    dataType: 'string',
    name: 'exampleString',
  },
}

const ATTRIBUTES_WITH_DESCRIPTIONS = cloneDeep(SIMPLE_ATTRIBUTES)
Object.keys(ATTRIBUTES_WITH_DESCRIPTIONS).forEach((key) => {
  ATTRIBUTES_WITH_DESCRIPTIONS[key] = sortObjectKeysAlphabetically({
    ...ATTRIBUTES_WITH_DESCRIPTIONS[key],
    description: '',
  })
})

const SIMPLE_DEVICE_TRIGGER_INFO = {
  // edge workflows
  applicationId: EXAMPLE_APP_ID,
  attributes: SIMPLE_ATTRIBUTES,
  creationDate: '2018-03-16T18:19:03.376Z',
  deviceClass: 'edgeCompute',
  deviceId: EXAMPLE_DEVICE_ID,
  id: EXAMPLE_DEVICE_ID,
  name: SIMPLE_DEVICE_INFO.deviceName,
  tags: SIMPLE_DEVICE_INFO.deviceTags,
}

export const CLOUD_DEVICE_TRIGGER_INFO = sortObjectKeysAlphabetically({
  // Device: Connect, Device: Disconnect, Device: State, Device: Inactive
  ...SIMPLE_DEVICE_TRIGGER_INFO,
  attributes: ATTRIBUTES_WITH_DESCRIPTIONS,
  _etag: '"567-SY26IHrVRzUBMUwxnY59Fn6vOvo"',
  description: '',
  deviceClass: 'standalone',
  lastUpdated: '2020-08-26T13:09:11.814Z',
})

export const getExecutionPayloadObject = ({ ...propertiesToAdd }) => {
  const merged = {
    // Job: Complete, Job: Iteration, Job: Timeout
    applicationId: EXAMPLE_APP_ID,
    context: {},
    id: '444444444444dddddddddddd',
    iterationDelay: 0,
    iterationTimeout: 900000,
    maxIterationConcurrency: 10,
    maxIterationRetries: 1,
    query: { $or: [] },
    resourceJobExecutionId: '444444444444dddddddddddd',
    resourceJobId: '6377e89a6bf4da30eb564c66',
    resourceType: 'device',
    retryDelay: 50,
    retryOnFailure: true,
    retryOnTimeout: true,
    runStartedAt: '2022-11-17T21:59:36.163Z',
    sourceId: EXAMPLE_APP_ID,
    sourceType: 'user',
    ...propertiesToAdd,
  }

  return sortObjectKeysAlphabetically(merged)
}

const FLOW_CLASS_SPECIFIC_PAYLOADS = {
  cloud: {},
  edge: {
    // SIMPLE_DEVICE_INFO will also be included
    agentEnvironment: {
      EXAMPLE: 'Environment Variable',
    },
    agentVersion: '1.31.0', // added in v1.31.1
    deviceId: EXAMPLE_DEVICE_ID,
    isConnectedToWnology: true, // extremely old edge agents won't have this. shrug.
    device: SIMPLE_DEVICE_TRIGGER_INFO, // added in v1.33.0
  },
  embedded: {
    deploymentId: '666666666666ffffffffffff',
    deviceId: EXAMPLE_DEVICE_ID,
    isConnectedToWnology: true,
    time: 'DATE_OBJECT', // overwrites baseExample.time. (time is in ms in embedded workflows.)
  },
  experience: {
    // need to fill in. would only apply to the Endpoint Trigger
    experience: {
      authInfo: {
        issuedAt: '<if a token was given this is the generation date>',
        expiresAt: '<if a token was given this is the expiration date>',
        extraData: '<if a token was given this is the extra data on the token>',
      },
      endpoint: {
        access: 'public',
        applicationId: '<application ID>',
        createdById: '<ID of resource that created the endpoint>',
        createdByType: 'user',
        creationDate: 'DATE_OBJECT',
        enabled: true,
        endpointTags: '<object of endpoint tags>',
        experienceEndpointId: '<ID of the endpoint>',
        experienceGroups: '<array of groups who have access to this endpoint>',
        id: '<ID of the endpoint trigger>',
        lastUpdated: 'DATE_OBJECT',
        lastUpdatedById: '<ID of resource that last updated this endpoint>',
        lastUpdatedByType: 'user',
        method: 'get',
        route: '/transition/up',
        version: 'develop',
      },
      user: {
        applicationId: '<ID of the application>',
        avatarUrl: null,
        creationDate: 'DATE_OBJECT',
        email: 'kanarra@example.com',
        experienceGroups: [
          {
            id: '<ID of the Experience Group>',
            name: '<Name of the Experience Group>',
            parentId: '<ID of the parent group, null if there is no parent>',
          },
        ],
        experienceUserId: '<ID of the Experience User>',
        id: '<ID of the Experience User>',
        lastLogin: 'DATE_OBJECT',
        lastUpdated: 'DATE_OBJECT',
        passwordLastUpdated: 'DATE_OBJECT',
        userTags: {
          adminRights: true,
        },
      },
      version: '<the experience version for the request>',
    },
  },
}

// alias since we use the two interchangeably
FLOW_CLASS_SPECIFIC_PAYLOADS.application = FLOW_CLASS_SPECIFIC_PAYLOADS.cloud

export const getPayloadObject = ({
  flowClass,
  payload,
  isCloudDeviceTrigger,
}) => {
  const merged = {
    ...BASE_PAYLOAD,
    ...(flowClass !== 'embedded' ? APPLICATION_INFO : {}),
    ...(flowClass === 'edge' || isCloudDeviceTrigger ? SIMPLE_DEVICE_INFO : {}),
    ...(isCloudDeviceTrigger ? { device: CLOUD_DEVICE_TRIGGER_INFO } : {}),
    ...(FLOW_CLASS_SPECIFIC_PAYLOADS[flowClass] || {}),
    ...payload, // always last so we can override what's spread above
  }

  // sort the top-level keys alphabetically
  // https://www.codegrepper.com/code-examples/javascript/alphabetize+the+properties+of+an+object+javascript
  return sortObjectKeysAlphabetically(merged)
}

const stringifyPayloadAndReplaceDates = (obj, { flowClass }) => {
  return JSON.stringify(obj, null, 2).replace(
    /"DATE_OBJECT"/g,
    flowClass === 'embedded'
      ? '1455902760000'
      : 'Fri Feb 19 2016 17:26:00 GMT-0500 (EST)'
  )
}

const TriggerPayload = ({ flowClass, payload, isCloudDeviceTrigger }) => {
  // based one this tutorial published on prismjs.com
  // https://betterstack.dev/blog/code-highlighting-in-react-using-prismjs/
  // but adapted based on this: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const elementToHighlight = useCallback((element) => {
    if (element !== null) {
      Prism.highlightElement(element)
    }
  }, [])

  const value = useMemo(() => {
    return stringifyPayloadAndReplaceDates(
      getPayloadObject({ flowClass, payload, isCloudDeviceTrigger }),
      { flowClass }
    )
  }, [flowClass, payload, isCloudDeviceTrigger])
  return (
    <pre className="language-json">
      <code ref={elementToHighlight} className="language-json">
        {value}
      </code>
    </pre>
  )
}

TriggerPayload.propTypes = {
  flowClass: PropTypes.oneOf([
    'application',
    'cloud',
    'edge',
    'embedded',
    'experience',
  ]).isRequired,
  isCloudDeviceTrigger: PropTypes.bool, // i.e. Device: State, Device: Connect, etc.
  payload: PropTypes.object.isRequired,
}

export default TriggerPayload
