import Test from '../../Test'

// Tests :)
import decodeJSON from './decodeJSON'
// import encodeJSON from './encodeJSON'
import decodeBase64 from './decodeBase64'
import encodeBase64 from './encodeBase64'
import decodeHex from './decodeHex'
import decodeXML from './decodeXML'
import encodeJWT from './encodeJWT'
// import decodeJWT from './decodeJWT'

const exercises = {}

exercises[decodeJSON.label] = new Test(decodeJSON)
// exercises[encodeJSON.label] = new Test(encodeJSON)
exercises[decodeBase64.label] = new Test(decodeBase64)
exercises[encodeBase64.label] = new Test(encodeBase64)
exercises[decodeHex.label] = new Test(decodeHex)
exercises[decodeXML.label] = new Test(decodeXML)
exercises[encodeJWT.label] = new Test(encodeJWT)
// exercises[decodeJWT.label] = new Test(decodeJWT)

export default {
  name: 'Encoding / Decoding',
  isRunning: false,
  webhook: null,
  description: `
  Each question in this test suite involves encoding and decoding data in various formats using a 
  variety of functionality within  the  Workflow Engine. 
  
  The Starter Workflow for this test suite is available [here](https://github.com/WEGnology/wegnology-workflow-lab/blob/e1c52d37c866a87fd3ec06e25f294603af7f8c58/starter-workflows/starter-workflow-encodingdecoding.flow).
  `,
  exercises,
}
