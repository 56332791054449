import React from 'react'
import { css } from '@emotion/core'

const Button = ({ children, onClick }) => {
  return (
    <button
      type="button"
      css={css`
        margin-top: 10px;
        background: rgb(124, 172, 25);
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-size: 14px;
        font-family: 'Alternate Gothic', 'Franklin Gothic Medium',
          'Arial Narrow', sans-serif;
        border-color: #dbdbdb;
        border-width: 1px;
        border-radius: 4px;
        cursor: pointer;
        justify-content: center;
        padding: calc(0.5em - 1px) 1em;
        text-align: center;
      `}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
