import * as C from 'chance'
import { assert } from 'chai'
import jwt from 'jsonwebtoken'
import Test from '../../Test'

const Chance = new C()

export const LABEL = 'encode-jwt'

export default {
  label: LABEL,
  name: 'Encoding JWT',
  action: `
  [JSON Web Tokens](https://jwt.io/) are an open, industry standard for representing claims securely between two parties. In this test,
  you will be creating a JWT Token. 
  
  A JSON object containing random "data" (a "string" and "num") and a "secret" 
  will be provided to your Webhook. You workflow needs to return a JSON object containing a single field, "token", that
  is the encoded JWT token value of "data" using "secret" to sign the token.
  `,
  examples: [
    {
      input: {
        data: {
          string: 'example',
          num: 4,
        },
        secret: 'SECRET',
      },
      output: {
        token: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdHJpbmciOiJleGFtcGxlIiwibnVtIjo0fQ.tr0A5yGnZq7rE2N-Qow_t35ANyTu2v4eNWEkg7-RqfY`,
      },
    },
  ],
  recommended: `Resources: [JWT: Create Node](/workflows/logic/jwt-create/)`,

  async exercise(userWebhook) {
    async function testIteration(webhook, test) {
      const string = Chance.word()
      const num = Chance.word()
      const secret = Chance.hash()

      const input = {
        data: {
          string,
          num,
        },
        secret,
      }
      const expectedValue = jwt.sign(input.data, input.secret)
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, LABEL, input)
      const expected = { token: expectedValue }

      const actual = body.data
      let passed = false
      let message = null
      let decodedToken

      try {
        decodedToken = jwt.verify(body.data.token, secret)
      } catch (error) {
        passed = false
        message = 'Could not verify token. Are you using the right secret?'
      }

      if (decodedToken) {
        try {
          assert.equal(
            string,
            decodedToken.string,
            `Expected string to equal ${string}, received ${decodedToken.string}`
          )
          assert.equal(
            num,
            decodedToken.num,
            `Expected num to equal ${num} received ${decodedToken.num}`
          )
          passed = true
        } catch (error) {
          passed = false
          message = error
        }
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          body,
          passed,
          message,
        },
        passed,
        message
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], testIteration)

    return this
  },
}
