import { useState, useReducer } from 'react'
import { toast } from 'react-toastify'
import { isHttpsUri } from 'valid-url'
import { trackEvent } from '../../util/analtyics'

const CounterReducer = (state, action) => {
  const { type, suite, metric, count } = action

  switch (type) {
    case 'init': {
      state[suite] = {
        pass: 0,
        fail: 0,
        notRun: count,
        count,
      }
      return { ...state }
    }
    case 'increment': {
      state[suite][metric] += 1
      return { ...state }
    }
    case 'decrement': {
      state[suite][metric] -= 1
      if (state[suite][metric] < 0) state[suite][metric] = 0
      return { ...state }
    }
    default:
      throw new Error('Unexpected action')
  }
}

function Runner(intialProblems) {
  const [problems, setProblems] = useState(intialProblems)
  const [counter, dispatch] = useReducer(CounterReducer, {})
  const [isRunning, setIsRunning] = useState(false)

  const run = async (groupName, label) => {
    if (isRunning) return
    setIsRunning(true)
    let currentTest = problems[groupName].exercises[label]
    // set this problem to running and update state
    currentTest.isRunning = true
    setProblems({ ...problems })

    // checking for a webhook
    if (
      !problems[groupName].webhook ||
      !isHttpsUri(problems[groupName].webhook)
    ) {
      toast.error(`${problems[groupName].name} Suite: Invalid Webhook`)
      setProblems({ ...problems })
      currentTest.isRunning = false
      return
    }

    currentTest.clearContext() // clear the test context before running

    if (currentTest.run) {
      dispatch({ suite: groupName, type: 'increment', metric: 'notRun' })
      if (currentTest.passed) {
        dispatch({ suite: groupName, type: 'decrement', metric: 'pass' })
      } else {
        dispatch({ suite: groupName, type: 'decrement', metric: 'fail' })
      }
    }

    // run the problem
    const result = await currentTest.exercise(problems[groupName].webhook)
    currentTest = result

    const eventCategory = `Workflow Lab - ${problems[groupName].name}`
    const eventLabel = result.name
    if (result.passed) {
      dispatch({ suite: groupName, type: 'increment', metric: 'pass' })
      trackEvent({
        eventCategory,
        eventAction: 'Pass',
        eventLabel,
      })
    } else {
      dispatch({ suite: groupName, type: 'increment', metric: 'fail' })
      trackEvent({
        eventCategory,
        eventAction: 'Fail',
        eventLabel,
      })
    }

    dispatch({ suite: groupName, type: 'decrement', metric: 'notRun' })

    currentTest.run = true
    currentTest.isRunning = false
    setProblems({ ...problems })
    setIsRunning(false)
  }

  const runSuite = async (groupName) => {
    if (isRunning) return
    const keys = Object.keys(problems[groupName].exercises)

    if (
      !problems[groupName].webhook ||
      !isHttpsUri(problems[groupName].webhook)
    ) {
      toast.error(`${problems[groupName].name} Suite: Invalid Webhook`)
      setProblems({ ...problems })
      problems[groupName].isRunning = false
      return
    }

    problems[groupName].isRunning = true
    setProblems({ ...problems })
    for (const key of keys) {
      await run(groupName, problems[groupName].exercises[key].label)
    }
    problems[groupName].isRunning = false
    setProblems({ ...problems })
    trackEvent({
      eventCategory: `Workflow Lab`,
      eventAction: 'Suite',
      eventLabel: problems[groupName].name,
    })
  }

  /**
   *
   * @param {Object} webhooks - an object of webhooks where the key is the groupName
   */
  const runAll = async () => {
    if (isRunning) return
    let thereIsOneWebhook = false
    const keys = Object.keys(problems).filter((key) => {
      // there is a property on the root of the problems called meta
      // we want to filter that out
      const isNotMeta = key !== 'meta'
      // first, let's check to see if any webhook is set
      // if not, we can give them a nice message
      if (isNotMeta && problems[key].webhook) {
        thereIsOneWebhook = true
      }
      return isNotMeta
    })

    if (!thereIsOneWebhook) {
      if (!toast.isActive('noWebhooks')) {
        toast.info(
          `Looks like you haven't configured any Webhooks yet. To run, configure a Problem Suite below.`,
          {
            toastId: 'noWebhooks',
          }
        )
      } else {
        toast.update('noWebhooks', {
          autoClose: 5000,
        })
      }
      return
    }
    problems.meta.isRunning = true
    setProblems({ ...problems })
    // loop through all the suite and run them all
    for (const groupName of keys) {
      await runSuite(groupName)
    }
    problems.meta.isRunning = false
    setProblems({ ...problems })
    if (!toast.isActive('complete')) {
      toast.success(`Done running all exercises.`, {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: 'complete',
      })
    } else {
      toast.update('complete', {
        autoClose: 5000,
      })
    }
    trackEvent({
      eventCategory: `Workflow Lab`,
      eventAction: 'Run All',
    })
  }

  return [problems, setProblems, run, runSuite, runAll, counter, dispatch]
}

export default Runner
