import React, { useReducer } from 'react'
import { Helmet } from 'react-helmet'

// This ensures that the icon CSS is loaded immediately before attempting to render icons
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
// Prevent fontawesome from dynamically adding its css since we did it manually above

import '../styles/layout.scss'
import LayoutStyles from '../styles/layout.module.scss'
import TopBar from './TopBar'
import LayoutContext from './LayoutContext'
import UserContext from './UserContext'
import useWEGnologyFetch from '../hooks/useWEGnologyFetch'

config.autoAddCss = false

const initialState = {
  post: null,
  isMobileNavOpen: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialState
    case 'post':
      return { ...state, post: action.post }
    case 'toggleMobileNav':
      return { ...state, isMobileNavOpen: action.state }
    default:
      return initialState
  }
}

const Layout = ({ children, location }) => {
  const [layoutState, layoutDispatch] = useReducer(reducer, initialState)
  const ctx = useWEGnologyFetch('/me?summaryInclude=orgCount')
  return (
    <UserContext.Provider value={ctx}>
      <LayoutContext.Provider value={{ layoutState, layoutDispatch }}>
        <div className="container">
          <Helmet>
            <title>WEGnology Documentation</title>
          </Helmet>
          <TopBar location={location} />
          <div className={LayoutStyles.MainContent}>{children}</div>
        </div>
      </LayoutContext.Provider>
    </UserContext.Provider>
  )
}
export default Layout
