import * as C from 'chance'
import { assert } from 'chai'
import Test from '../../Test'

const Chance = new C()
export const LABEL = 'decode-xml'

export default {
  label: LABEL,
  name: 'Decoding XML',
  action: `
  The Workflow Engine is commonly used to parse data from third-party APIs, which sometimes send data in XML format. In this test, you will build a workflow that parses XML data.

  An XML document containing a "Sensor" root tag with three child sensor tags, "Temperature", "Voltage", and "Current" will
  be provided to your workflow. Each sensor tag will contain two child tags, "Value" and "Threshold".
  Return a JSON object containing "temperature", "voltage", and "current" fields. Each field is set to true
  if the corresponding sensor's value is above the associated threshold, and false otherwise.
  
  `,
  recommended: `Resources: [HTML/XML Parser Node](/workflows/logic/html-parser/), [Conditional Node](/workflows/logic/conditional/) or [Conditional Block Helpers](/workflows/accessing-payload-data/#json-templates)`,
  examples: [
    {
      input: `<?xml version="1.0" encoding="UTF-8"?>
<Sensor>
  <Temperature>
    <Value>100</Value>
    <Threshold>95</Threshold>
  </Temperature>
  <Voltage>
    <Value>12</Value>
    <Threshold>14</Threshold>
  </Voltage>
  <Current>
    <Value>15</Value>
    <Threshold>30</Threshold>
  </Current>
</Sensor>`,
      output: { result: { temperature: true, voltage: false, current: false } },
    },
  ],
  async exercise(userWebhook) {
    async function testIteration(webhook, test) {
      const tempValue = Chance.integer({
        min: 0,
        max: 100,
      })
      const tempThreshold = Chance.integer({
        min: 0,
        max: 100,
      })
      const voltValue = Chance.integer({
        min: 0,
        max: 100,
      })
      const voltThreshold = Chance.integer({
        min: 0,
        max: 100,
      })
      const currentValue = Chance.integer({
        min: 0,
        max: 100,
      })
      const currentThreshold = Chance.integer({
        min: 0,
        max: 100,
      })

      const temperature = tempValue > tempThreshold
      const voltage = voltValue > voltThreshold
      const current = currentValue > currentThreshold

      const input = `<?xml version="1.0" encoding="UTF-8"?>
<Sensor>
  <Temperature>
    <Value>${tempValue}</Value>
    <Threshold>${tempThreshold}</Threshold>
  </Temperature>
  <Voltage>
    <Value>${voltValue}</Value>
    <Threshold>${voltThreshold}</Threshold>
  </Voltage>
  <Current>
    <Value>${currentValue}</Value>
    <Threshold>${currentThreshold}</Threshold>
  </Current>
</Sensor>`

      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input, {
        headers: { 'content-type': 'application/xml' },
      })
      const expectedValue = { temperature, voltage, current }
      const actual = body.data
      const expected = { result: expectedValue }
      let passed = false
      const message = null

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          body,
          message,
        },
        passed,
        message
      )

      return passed
    }
    await Test.performMultipleTimes(5, [userWebhook, this], testIteration)

    return this
  },
}
