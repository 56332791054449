import * as C from 'chance'
import { assert } from 'chai'

import Test from '../../Test'

const Chance = new C()

export const LABEL = 'mutate-move-add'

export default {
  label: LABEL,
  name: 'Mutate Move and Add',
  action: `
  Now, that we've added, copied, and removed values, sometimes we may need to _move_ values from one part of the
  payload to another. This is helpful if you have a deeply nested value (\`anObject.anotherObject.data.values.moreData\`) 
  and you would like to get that value to a place on the payload that's easier to work with, like \`working.data\`.
  
  In this test, a deeply nested JSON object containing random data at \`telemetry\` will be provided to your Webhook. In \`result\`, return the same JSON object 
  with \`telemetry\` moved under the \`working\` object. Also, add a new field to \`working\` called \`text\` with the value of "I love WEGnology".
  `,
  recommended: `Resources: [Mutate Node](/workflows/logic/conditional/)`,
  examples: [
    {
      input: {
        deeply: {
          nested: {
            object: {
              telemetry: {
                temperature: 97,
                machineFaultCode: 1284,
              },
            },
          },
        },
        working: {},
      },
      output: {
        result: {
          deeply: {
            nested: {
              object: {},
            },
          },
          working: {
            telemetry: {
              temperature: 97,
              machineFaultCode: 1284,
            },
            text: 'I love WEGnology',
          },
        },
      },
    },
  ],
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function mutateAdd(webhook, test) {
      const input = {
        deeply: {
          nested: {
            object: {
              telemetry: {
                temperature: Chance.integer({ min: -20, max: 50 }),
                machineFaultCode: Chance.integer({ min: 1000, max: 3000 }),
              },
            },
          },
        },
        working: {},
      }

      const expectedValue = {
        deeply: {
          nested: {
            object: {},
          },
        },
        working: {
          telemetry: input.deeply.nested.object.telemetry,
          text: 'I love WEGnology',
        },
      }

      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false

      try {
        assert.deepEqual(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message: null,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], mutateAdd)

    return this
  },
}
