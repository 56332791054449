import { assert } from 'chai'

import Test from '../../Test'

export const LABEL = 'trim-helper'

export default {
  label: LABEL,
  name: 'Trimming Strings',
  action: `
  There are a couple of useful tools to keep in your parsing toolkit. 
  [Trimming strings](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim) can 
  be very useful. 
  
  In this test, a JSON object containing a random string, "string", will be provided to your Webhook.
  Return a JSON object containing a single field, "result", that is the string trimmed of all whitespace.
  `,

  examples: [
    {
      input: { string: ' abba ' },
      output: { result: 'abba' },
    },
  ],
  recommended: `Resources: [String Node](/workflows/logic/string/), [Format Helpers](/workflows/accessing-payload-data/#format-helpers)`,

  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function trimTemplate(webhook, test) {
      const whitespaceString = Math.random().toString(36).substring(2, 15)
      const string = ` ${whitespaceString} `

      const expectedValue = whitespaceString
      const input = { string }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], trimTemplate)

    return this
  },
}
