import { assert } from 'chai'
import Test from '../../Test'

export const LABEL = 'add-helper'

export default {
  label: LABEL,
  name: 'Add Two Numbers',
  examples: [
    {
      input: { num1: 3, num2: 5 },
      output: {
        result: 8,
      },
    },
    {
      input: {
        num1: 7,
        num2: 21,
      },
      output: { result: 28 },
    },
  ],
  action: `
  In the Math Suite, you added two numbers using the Math Node. But, throughout the Workflow Engine, we support 
  templates. Using helpers, you can add numbers right from within a template. Let's try that out. 
  
  A JSON object containing two random numbers, "num1" and "num2", will be provided to your Webhook.
  Your workflow should return a JSON object containing a single field, "result", that is the sum of "num1" and "num2".`,
  recommended: `Resources: [Format Helpers](/workflows/accessing-payload-data/#format-helpers)`,
  async exercise(userWebhook) {
    // Tests need to be in a function to run multiple times
    async function addTwoNumbers(webhook, test) {
      const num1 = Math.floor(Math.random() * 100)
      const num2 = Math.floor(Math.random() * 100)

      const expectedValue = num1 + num2
      const input = { num1, num2 }
      // Test.call will actually trigger the workflow
      const body = await Test.call(webhook, test.label, input)
      const expected = { result: expectedValue }
      const actual = body.data

      let passed = false
      const message = null

      try {
        assert.equal(
          body.data.result,
          expectedValue,
          `Expected result to equal ${expectedValue}`
        )
        passed = true
      } catch (error) {
        passed = false
      }

      test.addContext(
        {
          input,
          expected,
          actual,
          passed,
          message,
          body,
        },
        passed
      )

      return passed
    }

    await Test.performMultipleTimes(5, [userWebhook, this], addTwoNumbers)

    return this
  },
}
