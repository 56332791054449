import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const CopyBox = ({ label, copyText, showText }) => {
  const [copied, setCopied] = useState(false)

  const CopyWrapper = styled.div`
    font-size: 12px;
    margin: 0px;
    background: #f5f6f7;
    box-shadow: none;
    display: flex;
    width: fit-content;
    padding: 9px;
    border-radius: 4px;
    min-height: 20px;
    border: 1px solid #e3e3e3;
    order: 1px solid #e3e3e3;
    box-sizing: border-box;
  `

  const TextWrapper = styled.div`
    padding-top: 3px;
    margin-right: 8px;
  `

  const CopyButton = styled.button`
    cursor: pointer;
    min-width: 40px;
    outline: none;
    transition: all 200ms ease;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid transparent;
    user-select: none;
    color: rgb(136, 140, 149);
    background: rgb(255, 255, 255);
    border-color: rgb(204, 204, 204);
    ${(props) => (props.copied ? 'color: rgb(141, 179, 25);' : '')}
    &: {
      background-color: rgb(236, 237, 238) !important;
    }
  `

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false)
    }, 1000)
    return () => clearTimeout(timer)
  }, [copied])

  const copy = () => {
    setCopied(true)
  }

  let labelOutput

  if (showText) {
    labelOutput = (
      <TextWrapper>
        <strong>{label}</strong>: {copyText}
      </TextWrapper>
    )
  } else {
    labelOutput = (
      <TextWrapper>
        <strong>{label}</strong>
      </TextWrapper>
    )
  }

  return (
    <CopyWrapper>
      {labelOutput}
      <CopyToClipboard text={copyText} onCopy={copy}>
        <CopyButton copied={copied}>{copied ? 'Done' : 'Copy'}</CopyButton>
      </CopyToClipboard>
    </CopyWrapper>
  )
}

export default CopyBox
